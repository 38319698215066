<template>
    <div class="float-container">
        <div class="float-content">
            <img class="float-bg" src="@/assets/image/pc/home/float-bg.png" />
            <div class="button-group">
                <a href="https://ob.gg.com" class="action-btn">
                    <img src="@/assets/image/pc/home/btn-register.png" />
                </a>
                <a href="https://mj.gg.com" class="action-btn">
                    <img src="@/assets/image/pc/home/btn-game.png" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FloatContainer",
};
</script>

<style lang="scss" scoped>
/* 悬浮窗口容器 */
.float-container {
    position: fixed;
    /* 固定在页面上 */
    left: 10px;
    /* 距离左侧的距离 */
    top: 110px;
    /* 距离顶部的距离 */
    z-index: 1000;
    /* 确保悬浮窗口在最上层 */
    width: 390px;
    /* 设置宽度 */
    height: 214px;
    /* 设置高度 */
}

/* 背景图容器 */
.float-content {
    position: relative;
    width: 100%;
    height: 100%;
}

/* 背景图样式 */
.float-bg {
    width: 100%;
    height: 100%;
    animation: float 2s ease-in-out infinite;
    /* 悬浮动画 */
}

/* 按钮组布局 */
.button-group {
    position: absolute;
    top: 79%;
    /* 垂直居中 */
    left: 50%;
    /* 水平居中 */
    transform: translate(-50%, -50%);
    /* 居中 */
    display: flex;
    flex-direction: row;
    /* 水平排列 */
    gap: 20px;
    /* 按钮之间的间距 */
}

/* 按钮样式 */
.action-btn {
    display: block;
    width: 163.4px;
    height: 54.1px;
    /* 根据按钮图片比例调整 */
    transition: all 0.3s;
}

.action-btn img {
    width: 100%;
}

.action-btn:hover {
    transform: scale(1.08);
    filter: brightness(1.1);
}

.action-btn:active {
    transform: scale(0.95);
}
</style>