<template>
  <div class="scrowview">
    <swiper
      :slides-per-view="2"
      :space-between="50"
      style="height: 100%"
      :options="swiperOptions"
    >
      <swiper-slide>
        <div class="bg">
          <img src="@/assets/image/pc/home/banner5.jpg" />
        </div>
        <div class="logo">
          <img src="@/assets/image/pc/home/mj_logo.png" />
        </div>
        <div class="description">
          <p class="gamedes3">
            《激情麻将》是一个全3D二次元<span class="highlight-text">「棋牌游戏平台」</span><br />
            <span class="small-text">包含立直麻将、四川麻将、斗地主等主流玩法</span>
          </p>
        </div>
        <!-- <a
          href="https://mj.gg.com/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="button">
            <p class="buttonContent">查看游戏</p>
          </div>
        </a> -->

        <a href="https://mj.gg.com/home" target="_blank">
          <div class="button">
            <p class="buttonContent">查看游戏</p>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide>
        <div class="bg">
          <img src="@/assets/image/pc/home/banner6.jpg" />
        </div>
        <!-- <div class="logo">
          <img src="@/assets/image/pc/home/mj_logo.png" />
        </div> -->
        <!-- <div class="description">
          <p class="gamedes3">
            《激情麻将》是一个全3D二次元棋牌游戏平台，<br />
            包含立直麻将、四川麻将等主流玩法。
          </p>
        </div> -->
        <!-- <a
          href="https://mj.gg.com/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="button">
            <p class="buttonContent">查看游戏</p>
          </div>
        </a> -->

        <a href="https://qp.gg.com" target="_blank">
          <div class="button">
            <p class="buttonContent">查看游戏</p>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide>
        <div class="bg">
          <img src="@/assets/image/pc/home/banner2.jpg" />
        </div>
        <div class="logo1">
          <img src="@/assets/image/pc/home/我的花园2.png" />
        </div>
        <div class="description">
          <p class="gamedes">
            在神秘的大宅中肆意装修,轻松解谜<br />拥有属于你的浪漫庄园
          </p>
        </div>
        <a href="https://hy.gg.com/" target="_blank">
          <div class="button">
            <p class="buttonContent">查看游戏</p>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide>
        <div class="bg">
          <img src="@/assets/image/pc/home/banner4.jpg" />
        </div>
        <div class="logo2">
          <img src="@/assets/image/pc/home/game3logo.png" />
        </div>
        <div class="description">
          <p class="gamedes2">
            以“东方Project”为原型的<br />二次创作回合制RPG手游
          </p>
        </div>
        <a href="https://lw.yifanghy.com/#/" target="_blank">
          <div class="button">
            <p class="buttonContent">查看游戏</p>
          </div>
        </a>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination" />
    <img class="position" src="@/assets/image/pc/home/position.png" />
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "ScrowView",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        effect: "fade",
        // effect: "flip",
        // effect: "creative",
        autoplay: true,
        loop: true,
        // effect: "fade",
        speed: 1000,
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // bulletClass: 'my-bullet', // 需设置.my-bullet样式
          // bulletActiveClass: 'my-bullet-active'
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // }
      },
    };
  },
  // setup() {
  //   const onSwiper = (swiper) => {
  //     console.log(swiper);
  //   };
  //   const onSlideChange = () => {
  //     console.log('slide change');
  //   };
  //   return {
  //     onSwiper,
  //     onSlideChange
  //   };
  // },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.small-text {
  font-size: 20px; /* 比默认字体大 */
}
.highlight-text {
  font-size: 35px; /* 比默认字体大 */
  font-weight: bold; /* 如果需要加粗 */
}
.swiper {
  --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
}
.swiper-pagination {
  width: 100px;
  position: absolute;
  left: 905px;
  top: 800px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.scrowview {
  width: 1920px;
  background-size: 100%;
  position: relative;
  height: 872px;
  .bg {
    width: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    img {
      width: 100%;
    }
  }
  .logo {
    position: absolute;
    top: 140px;
    left: 625px;
    img {
      width: 610px;
      height: 260px;
    }
  }
  .logo1 {
    position: absolute;
    top: 475px;
    left: 840px;
    img {
      width: 205px;
      height: 71px;
    }
  }
  .logo2 {
    position: absolute;
    top: 450px;
    left: 840px;
    img {
      width: 239px;
      height: 120px;
    }
  }
  .description {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140%;
    .gamedes {
      text-align: center;
      width: 480px;
      font-size: 30px;
      font-family: "Source Han Sans", sans-serif;
      font-weight: bold;
      color: #ffffff;
      line-height: 41px;
      text-shadow: -11px 4px 23px rgba(68, 32, 102, 0.76);
    }
    .gamedes2 {
      text-align: center;
      width: 480px;
      font-size: 30px;
      font-family: "Source Han Sans", sans-serif;
      font-weight: bold;
      color: #ffffff;
      line-height: 41px;
      text-shadow: 0 0 15px #800000;
    }
    .gamedes3 {
      text-align: center;
      width: 800px;
      font-size: 30px;
      font-family: "Source Han Sans", sans-serif;
      font-weight: bold;
      color: #144975;
      line-height: 35px;
      text-shadow: 2px 2px 5px rgb(255, 255, 255);
    }
  }
  .button {
    top: 674px;
    left: 832px;
    width: 250px;
    height: 61px;
    background: #e60012;
    border-radius: 31px;
    position: absolute;
    cursor: pointer;
    .buttonContent {
      text-align: center;
      cursor: pointer;
      margin-top: 6px;
      font-size: 33px;
      font-family: "Source Han Sans", sans-serif;
      font-weight: 400;
      color: #fdfefa;
      // line-height: 47px;
    }
  }
}
</style>
<style lang="scss">
.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 1;
  width: 0.5rem;
  height: 0.5rem;
  background-size: 100% 100%;
}
.swiper-pagination-bullet-active {
  background: transparent;
  width: 1rem;
  height: 1rem;
  background-image: url(../../../assets/image/pc/home/选中.png);
  background-size: 100% 100%;
}
</style>
