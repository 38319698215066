<template>
  <div class="games">
    <div class="title_en">OUR GAMES</div>
    <div class="title_zh">我们的游戏</div>
    <div class="games_img">
      <div class="game1">
        <img class="game_bg" src="@/assets/image/pc/home/mj.jpg">
        <a href="https://mj.gg.com/" target="_blank">
          <img class="enter_link" src="@/assets/image/pc/home/进入官网.png">
        </a>
      </div>
      <div class="game4">
        <img class="game_bg" src="@/assets/image/pc/home/gg.jpg">
        <a href="https://qp.gg.com/" target="_blank">
          <img class="enter_link" src="@/assets/image/pc/home/进入官网.png">
        </a>
      </div>
      <div class="game2">
        <img class="game_bg" src="@/assets/image/pc/home/hy.jpg">
        <a href="https://hy.gg.com/" target="_blank">
          <img class="enter_link" src="@/assets/image/pc/home/进入官网.png">
        </a>
      </div>
      <div class="game3">
        <img class="game_bg" src="@/assets/image/pc/home/game3.jpg">
        <a href="https://th.gg.com/" target="_blank">
          <img class="enter_link" src="@/assets/image/pc/home/进入官网.png">
        </a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Games"
}
</script>

<style lang="scss" scoped>
.games {
  height: 1150px; /* 改为自适应高度 */
  margin-top: 64px;
  .title_en {
    text-align: center;
    font-size: 80px;
    font-family: Poppins;
    font-weight: 800;
    color: #343434;
    line-height: 104px;
    opacity: 0.76;
  }
  .title_zh {
    text-align: center;
    font-size: 35px;
    font-family: 'Source Han Sans', sans-serif;
    font-weight: bold;
    color: #EB3644;
    line-height: 46px;
  }
  .games_img {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    justify-content: center; /* 居中显示，左右留出间距 */
    gap: 30px 45px; /* 设置间距 */
    padding: 0 145px; /* 左右两侧添加间距 */
    .game_bg {
      width: 778px;
      height: 445px;
      object-fit: cover; /* 确保图片比例不变 */
    }
    .game1, .game2, .game3, .game4 {
      width: calc(50% - 40px); /* 每个 game 占 50% 宽度，减去间距 */
      position: relative; /* 相对定位 */
      .enter_link {
        position: absolute; /* 绝对定位 */
        bottom: 30px; /* 距离底部 20px */
        left: 38px; /* 距离左侧 38px */
        width: 184px;
        height: 45px;
        cursor: pointer;
        border-radius: 22px;
      }
    }
    .game3{
      .enter_link {
        position: absolute; /* 绝对定位 */
        bottom: 30px; /* 距离底部 20px */
        left: 38px; /* 距离左侧 38px */
        width: 184px;
        height: 45px;
        cursor: pointer;
        background: rgba(139, 109, 86, 0.5);
        border-radius: 22px;
      }
    }
  }
}
</style>
