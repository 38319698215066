<template>
  <div>
    <FloatContainer />
    <div class="home">
      <Head />
      <ScrowView />
      <Games />
      <!-- <News /> -->
      <Career />
      <Floor />
    </div>
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import ScrowView from './ScrowView'
import Games from './Games'
// import News from './News'
import Career from './Career'
import FloatContainer from './Float'

export default ({
  name: "Home",
  components: {
    Head,
    Floor,
    ScrowView,
    Games,
    // News,
    Career,
    FloatContainer
  }
})
</script>

<style lang="scss" scoped>
.home {
  // display: flex;
  // width: 100%;
  // height: 100%;
  //  position: relative;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// .box{
//   margin: 0,auto;
// }</style>
